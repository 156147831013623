<ng-container *ngIf="module$ | async as module">
    <mat-toolbar class="toolbar">
        <div class="toolbar-left">
            {{ 'New ' + module.name }}
        </div>
        <div class="toolbar-right">
            <button mat-icon-button [routerLink]="['..']"><mat-icon>close</mat-icon></button>
            <button mat-icon-button color="primary" [disabled]="!formState?.valid" (click)="onSave(module)"><mat-icon>check</mat-icon></button>
        </div>
    </mat-toolbar>
    <mat-divider></mat-divider>
    <div class="form">
        <app-record-form [module]="module" [formMode]="EDIT" (formChange)="onFormChange($event)"></app-record-form>
    </div>
</ng-container>