<!-- <ng-container *ngIf="templateData$ | async as data">
    <sol-form [form]="data.form" [module]="module" [record]="record" [layoutId]="data.layout.id"></sol-form>
</ng-container> -->

<div class="construction-container">
    <div class="construction-emoji">🚧</div>
    <div class="construction-text">
      <h1>Oops! This page is under construction</h1>
      <p>We're working hard to bring this page to life. Check back soon!</p>
    </div>
  </div>
  