import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IDataRole, ILayout, IModule, IRecord } from '@soleran/contracts';
import { AuthService } from '@soleran/ngx-auth';
import { RoleService } from '@soleran/ngx-role';
import { NEVER, Observable, combineLatest, map, mergeMap, of, share } from 'rxjs';

interface IRecordEditData {
  record: IRecord,
  layout: ILayout,
  form: FormGroup
}

const NO_ROLE_MESSAGE = 'No role assigned to user';

@Component({
  selector: 'app-record-edit',
  templateUrl: './record-edit.component.html',
  styleUrl: './record-edit.component.scss'
})
export class RecordEditComponent {
  @Input({ required: true }) module!: IModule;
  @Input({ required: true }) record!: IRecord;
  @Input({ required: true }) form!: FormGroup;

  layout$: Observable<ILayout> = NEVER;
  form$: Observable<FormGroup> = NEVER;
  templateData$: Observable<IRecordEditData> = NEVER;
  
	role: IDataRole;
	errorMessage: string | null = null;

  constructor(private _roleService: RoleService, private _authService: AuthService, private _formBuilder: FormBuilder) { }
  ngOnInit(): void {
    this._initLayout();
    this._initForm();
    this._initTemplateData();
  }
  private _initForm() {
    this.form$ = of(this.form);
  }

  private _initTemplateData() {
    this.templateData$ = combineLatest([this.layout$, this.form$]).pipe(
      map(([layout, form]) => {
        const templateData: IRecordEditData = {
          record: this.record,
          layout,
          form
        };
        return templateData;
      })
    )
  }

  private _initLayout() {
    // this.layout$ = this._masterLayoutService.getByModule(this.module.id).pipe(
    //   map(layouts => ({ layouts, moduleId: this.module.id })),
    //   mergeMap(({ layouts, moduleId }) => this._findLayoutByRole(layouts, moduleId)),
    //   share()
    // );
  }
  private _findLayoutByRole(layouts: ILayout[], moduleId: string) {
    const dataRoles$ = this._roleService.getDataRole().pipe(share());
    const userRole$ = dataRoles$.pipe(
      map(roles => {
        const currentUser = this._authService.currentUser();
        const roleId = currentUser?.claims.roles?.data?.primary;
        const role = roles.find(r => r.id === roleId);
        if (!role) this.errorMessage = NO_ROLE_MESSAGE;
        this.role = role;
        return role;
      })
    );
    const layout$ = userRole$.pipe(
      map(role => {
        const permissions = role?.permissions;
        const layoutId = permissions ? permissions[moduleId]?.layoutId : undefined;
        const layout = layouts.find(l => l.id === layoutId);
        return layout ?? this._getDefaultLayout(layouts);
      })
    )
    return layout$;
  }	private _getDefaultLayout(layouts: ILayout[]) {
		const sortedByCreateDate = layouts.sort((a, b) => {
			const aCreatedDate = new Date(a.created);
			const bCreatedDate = new Date(b.created);
			return aCreatedDate.getTime() - bCreatedDate.getTime();
		});
		const [firstLayout] = sortedByCreateDate;
		return firstLayout;
	}

  onFormChange(event: any) {
  }
}
