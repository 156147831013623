<ng-container *ngIf="templateData$ | async as data">
    <mat-toolbar class="toolbar">
        <div class="toolbar-left">
            {{ getKeyField(data) }}
        </div>
        <div class="toolbar-right">
            <button mat-icon-button color="primary" *ngIf="formMode === VIEW" [matTooltip]="'View All ' + pluralize(data.module.name)" [matTooltipShowDelay]="350"
                [routerLink]="['..']"><span class="material-symbols-outlined icon-faded">
                    list
                    </span></button>
            <button mat-icon-button color="primary" *ngIf="formMode === VIEW" [matTooltip]="'Add ' + data.module.name" [matTooltipShowDelay]="350"
                [routerLink]="['..', 'create']"><mat-icon>add</mat-icon></button>
            <button mat-icon-button color="primary" *ngIf="formMode === VIEW" [matTooltip]="'Edit'" [matTooltipShowDelay]="350"
                (click)="onEdit()"><mat-icon>edit</mat-icon></button>
            <button mat-icon-button *ngIf="formMode === EDIT" [matTooltip]="'Cancel'" [matTooltipShowDelay]="350"
                (click)="onCancel()"><mat-icon>close</mat-icon></button>
            <button mat-icon-button color="primary" *ngIf="formMode === EDIT" [disabled]="!formState.valid" [matTooltip]="'Save'" [matTooltipShowDelay]="350"
                (click)="onSave(data)"><mat-icon>check</mat-icon></button>
        </div>
    </mat-toolbar>
    <mat-divider></mat-divider>
    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav class="sidenav" mode="side" position="end" opened>
            <div class="sidenav-wrapper"
                [ngClass]="{ 'sidenav-wrapper-collapsed': !messageTrayExpanded, 'sidenav-wrapper-expanded': messageTrayExpanded }">
                <button class="chat-button" mat-icon-button (click)="toggleMessageTray()">
                    <mat-icon>chat</mat-icon>
                </button>
                <mat-divider vertical></mat-divider>
                <mat-tab-group class="tab-groups">
                    <mat-tab label="Chat">


                        <div style="padding: 2rem;">
                            <strong>Chat Component</strong>
                            <br>
                            {{ 'Record Id: ' + data.record?.id }}
                        </div>
                        

                    </mat-tab>
                    <mat-tab label="Email">
                        <div class="construction-container">
                            <div class="construction-emoji">🚧</div>
                            <div class="construction-text">
                                <h1>Oops! This page is under construction</h1>
                                <p>We're working hard to bring this page to life. Check back soon!</p>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-sidenav>
        <mat-sidenav-content class="sidenav-content">
            <div class="form">
                <app-record-form [module]="data.module" [record]="data.record" [formMode]="formMode" (formChange)="onFormChange($event)"></app-record-form>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>