import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i1$1 from '@angular/forms';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NEVER, Subscription } from 'rxjs';
import * as i1 from '@soleran/ngx-layout';
import { LayoutModule } from '@soleran/ngx-layout';
import * as i2 from '@soleran/ngx-control';
import { ControlModule } from '@soleran/ngx-control';
import { MatSelectModule } from '@angular/material/select';
class FormComponent {
  constructor(_layoutService) {
    this._layoutService = _layoutService;
    this.readonly = false;
    this.formChange = new EventEmitter();
    this.recordFormReady$ = NEVER;
    this.layouts$ = NEVER;
    this.layout$ = NEVER;
    this._subs = new Subscription();
  }
  ngOnInit() {
    this._initLayouts();
  }
  ngOnDestroy() {
    this._subs.unsubscribe();
  }
  ngOnChanges(changes) {
    if (changes['layoutId'] && !changes['layoutId'].firstChange) this._initLayouts();
  }
  _initLayouts() {
    this.layout$ = this._layoutService.get(this.layoutId);
    // const typeOverride = this.readonly ? LayoutContentType.Field : undefined;
    this.form = this.form ? this.form : new FormGroup({});
    // const moduleForm = this._formService.toFormGroup(this.module);
    // this._layoutFormService.populate(
    //   this.form,
    //   moduleForm,
    //   this.record, 
    //   typeOverride
    //   );
    //   const sub = this.form.valueChanges.subscribe(change => this.formChange.emit(change));
    //   this._subs.add(sub);
    // this.layouts$ = this._layoutService.getLineage(this.layoutId);
  }
  static {
    this.ɵfac = function FormComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FormComponent)(i0.ɵɵdirectiveInject(i1.LayoutService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FormComponent,
      selectors: [["sol-form"]],
      inputs: {
        layoutId: "layoutId",
        module: "module",
        record: "record",
        form: "form",
        readonly: "readonly"
      },
      outputs: {
        formChange: "formChange"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 8,
      vars: 0,
      consts: [[1, "construction-container"], [1, "construction-emoji"], [1, "construction-text"]],
      template: function FormComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
          i0.ɵɵtext(2, "\uD83D\uDEA7");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "div", 2)(4, "h1");
          i0.ɵɵtext(5, "Oops! This page is under construction");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(6, "p");
          i0.ɵɵtext(7, "We're working hard to bring this page to life. Check back soon!");
          i0.ɵɵelementEnd()()();
        }
      },
      styles: [".construction-container[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center;padding:20px;border:2px dashed #ffcc00;border-radius:10px;background-color:#fff9e6;max-width:400px;margin:50px auto;box-shadow:0 4px 8px #0000001a}.construction-emoji[_ngcontent-%COMP%]{font-size:4rem;margin-bottom:10px}.construction-text[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%]{font-size:1.5rem;color:#333;margin:0}.construction-text[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{font-size:1rem;color:#666;margin-top:10px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormComponent, [{
    type: Component,
    args: [{
      selector: 'sol-form',
      template: "<!-- <ng-container *ngIf=\"layout$ | async as layout\">\n  <sol-layout [module]=\"module\" [layout]=\"layout\" [recordForm]=\"form\" [record]=\"record\" [isReadOnly]=\"readonly\"></sol-layout>\n</ng-container> -->\n\n<div class=\"construction-container\">\n  <div class=\"construction-emoji\">\uD83D\uDEA7</div>\n  <div class=\"construction-text\">\n    <h1>Oops! This page is under construction</h1>\n    <p>We're working hard to bring this page to life. Check back soon!</p>\n  </div>\n</div>\n",
      styles: [".construction-container{display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center;padding:20px;border:2px dashed #ffcc00;border-radius:10px;background-color:#fff9e6;max-width:400px;margin:50px auto;box-shadow:0 4px 8px #0000001a}.construction-emoji{font-size:4rem;margin-bottom:10px}.construction-text h1{font-size:1.5rem;color:#333;margin:0}.construction-text p{font-size:1rem;color:#666;margin-top:10px}\n"]
    }]
  }], () => [{
    type: i1.LayoutService
  }], {
    layoutId: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    module: [{
      type: Input,
      args: [{
        required: true
      }]
    }],
    record: [{
      type: Input
    }],
    form: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    formChange: [{
      type: Output
    }]
  });
})();
class FormMaterialModule {
  static {
    this.ɵfac = function FormMaterialModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FormMaterialModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FormMaterialModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [MatSelectModule, MatSelectModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormMaterialModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [MatSelectModule],
      exports: [MatSelectModule]
    }]
  }], null, null);
})();
class FormModule {
  static {
    this.ɵfac = function FormModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FormModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FormModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormMaterialModule, ReactiveFormsModule, ControlModule, LayoutModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormModule, [{
    type: NgModule,
    args: [{
      declarations: [FormComponent],
      imports: [CommonModule, FormMaterialModule, ReactiveFormsModule, ControlModule, LayoutModule],
      exports: [FormComponent]
    }]
  }], null, null);
})();
class FormService {
  constructor(_formBuilder, _controlService, _controlConfigFormService) {
    this._formBuilder = _formBuilder;
    this._controlService = _controlService;
    this._controlConfigFormService = _controlConfigFormService;
  }
  toFormGroup(module) {
    return this._formBuilder.group({
      id: module.id,
      name: module.name,
      type: module.type,
      description: module.description,
      icon: module.icon,
      fields: this._getFieldsFormArray(module),
      keyField: module.keyField,
      relationships: this._getRelationshipsFormArray(module.relationships)
    });
  }
  relationshipToFormGroup(relationship, isNew = false) {
    return this._formBuilder.group({
      relatedModuleId: relationship.relatedModuleId,
      relationshipType: relationship.relationshipType,
      isParent: relationship.isParent,
      field: relationship.field,
      isSystem: relationship.isSystem,
      new: isNew
    });
  }
  _getRelationshipsFormArray(relationships) {
    if (!relationships) return undefined;
    const formArray = this._formBuilder.array([]);
    relationships.forEach(relationship => {
      const formGroup = this.relationshipToFormGroup(relationship);
      formArray.push(formGroup);
    });
    return formArray;
  }
  _getFieldsFormArray(module) {
    const fields = module.fields;
    if (!fields) return undefined;
    const formArray = this._formBuilder.array([]);
    fields.forEach(field => {
      const formGroup = this._controlConfigFormService.generateFieldForm(field, module);
      formArray.push(formGroup);
    });
    return formArray;
  }
  static {
    this.ɵfac = function FormService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FormService)(i0.ɵɵinject(i1$1.FormBuilder), i0.ɵɵinject(i2.ControlService), i0.ɵɵinject(i2.ControlConfigFormService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FormService,
      factory: FormService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.FormBuilder
  }, {
    type: i2.ControlService
  }, {
    type: i2.ControlConfigFormService
  }], null);
})();

/*
 * Public API Surface of module
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FormComponent, FormModule, FormService };
