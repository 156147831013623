import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IFormChange, IModule, IPage, IRecord, PageType } from '@soleran/contracts';
import { FormMode, IPageBuilderState, LayoutFormService, setPageType } from '@soleran/ngx-layout';
import { PageService } from '@soleran/ngx-page';
import { map, NEVER, Observable, tap } from 'rxjs';

interface IRecordFormData {
	page: IPage,
	module: IModule,
	record?: IRecord
}

@Component({
	selector: 'app-record-form',
	templateUrl: './record-form.component.html',
	styleUrls: ['./record-form.component.scss']
})
export class RecordFormComponent implements OnInit {
	@Input({ required: true }) module!: IModule;
	@Input() record?: IRecord;
	@Input() formMode: FormMode = FormMode.View;
	@Output() formChange = new EventEmitter<IFormChange<IRecord>>()

	page$: Observable<IPage> = NEVER;
	templateData$: Observable<IRecordFormData> = NEVER;

	constructor(
		private _pageService: PageService,
		private _formService: LayoutFormService,
		private _destroyRef: DestroyRef
	) { }

	ngOnInit(): void {
		this._initForm();
		this._initPage();
		this._initTemplateData();
	}
	private _initPage() {
		this.page$ = this._pageService.getByModuleId(this.module.id);
	}
	private _initTemplateData() {
		this.templateData$ = this.page$.pipe(
			map(page => {
				const templateData: IRecordFormData = {
					page,
					module: this.module,
					record: this.record
				};
				return templateData;
			})
		)
	}
	private _initForm() {
		this._formService.observeValueChanges().pipe(
			takeUntilDestroyed(this._destroyRef)
		).subscribe(change => {
			this.formChange.emit(change);
		})
	}
}
