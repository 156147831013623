import { Component, DestroyRef, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IFormChange, IModule, IPage, IRecord } from '@soleran/contracts';
import { isDefined } from '@soleran/ngx-common';
import { FormMode, IPageBuilderState, LayoutFormService, LayoutMode, resetState, setFormMode, setLayoutMode, setModule, setRecord, setSelectedPage } from '@soleran/ngx-layout';
import { ModuleService } from '@soleran/ngx-module';
import { PageService } from '@soleran/ngx-page';
import { RecordService } from '@soleran/ngx-record';
import { filter, map, mergeMap, NEVER, Observable } from 'rxjs';

@Component({
	selector: 'app-record-create',
	templateUrl: './record-create.component.html',
	styleUrl: './record-create.component.scss'
})
export class RecordCreateComponent implements OnInit, OnDestroy {
	module$: Observable<IModule> = NEVER;
	page$: Observable<IPage> = NEVER;

	formState?: IFormChange<IRecord>;
	EDIT = FormMode.Edit;

	constructor(
		private _router: Router,
		private _route: ActivatedRoute,
		private _store: Store<IPageBuilderState>,
		private _formService: LayoutFormService,
		private _moduleService: ModuleService,
		private _recordService: RecordService,
		private _pageService: PageService,
		private _destroyRef: DestroyRef
	) { }
	ngOnDestroy(): void {
		this._store.dispatch(resetState());
	}
	ngOnInit(): void {
		this._initForm();
		this._initModule();
		this._initPage();
	}
	private _initForm() {
		this._formService.init();
		this._store.dispatch(setRecord({ record: undefined }))
	}
	onFormChange(change: IFormChange<IRecord>) {
		this.formState = change;
	}
	onSave(module: IModule) {
		const model = this.formState.value;
		this._recordService.create(module.id, model).pipe(
			takeUntilDestroyed(this._destroyRef)
		).subscribe(record => this._router.navigate(['..', record.id], { relativeTo: this._route }));
	}
	private _initPage() {
		this.page$ = this.module$.pipe(
			mergeMap(module => this._pageService.get().pipe(map(pages => pages.find(page => page.moduleId === module.id)), filter(isDefined)))
		)
	}
	private _initModule() {
		const moduleId = this._route.snapshot.paramMap.get('id');
		this.module$ = this._moduleService.get(moduleId);
	}
}
