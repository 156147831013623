import { Component, DestroyRef, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { IFormChange, IModule, IPage, IRecord } from '@soleran/contracts';
import { isDefined } from '@soleran/ngx-common';
import { FormMode, IPageBuilderState, LayoutMode, resetState, selectFormMode, setFormMode, setLayoutMode, setModule, setRecord, setSelectedPage } from '@soleran/ngx-layout';
import { ModuleService } from '@soleran/ngx-module';
import { PageService } from '@soleran/ngx-page';
import { RecordService } from '@soleran/ngx-record';
import { BehaviorSubject, combineLatest, filter, map, mergeMap, NEVER, Observable, tap } from 'rxjs';
import pluralize from 'pluralize';

interface IRecordPageData {
	module: IModule,
	record: IRecord
}

@Component({
	selector: 'app-record-page',
	templateUrl: './record-page.component.html',
	styleUrls: ['./record-page.component.scss']
})
export class RecordPageComponent implements OnInit, OnDestroy {
	module$: Observable<IModule> = NEVER;
	record$: Observable<IRecord> = NEVER;
	recordSubject$ = new BehaviorSubject<IRecord | null>(null);
	page$: Observable<IPage> = NEVER;
	formMode$: Observable<FormMode> = NEVER;
	templateData$: Observable<IRecordPageData> = NEVER;
	
	formMode = FormMode.View
	formState?: IFormChange<IRecord>;
	messageTrayExpanded = false;

	EDIT = FormMode.Edit;
	VIEW = FormMode.View

	constructor(
		private _route: ActivatedRoute,
		private _store: Store<IPageBuilderState>,
		private _moduleService: ModuleService,
		private _recordService: RecordService,
		private _pageService: PageService,
		private _destroyRef: DestroyRef
	) { }

	ngOnInit(): void {
		this._initModule();
		this._initRecord();
		this._initPage();
		this._initPageBuilder();
		this._initTemplateData();
	}
	ngOnDestroy(): void {
		this._store.dispatch(resetState());
	}
	toggleMessageTray() {
		this.messageTrayExpanded = !this.messageTrayExpanded;
	}
	private _initTemplateData() {
		this.templateData$ = combineLatest([this.module$, this.record$]).pipe(
			tap(([module, record]) => console.log({record})),
			map(([module, record]) => {
				const templateData: IRecordPageData = {
					module,
					record
				};
				return templateData;
			})
		)
	}
	onFormChange(change: IFormChange<IRecord>) {
	  this.formState = change;
	}
	pluralize(singular: string) {
		return pluralize(singular);
	}
	private _initRecord() {
		this.record$ = this.recordSubject$.asObservable();
		this.module$.pipe(
			takeUntilDestroyed(this._destroyRef),
			mergeMap(module => {
				const recordId = this._route.snapshot.paramMap.get('recordId');
				return this._recordService.get(recordId, module.id).pipe(map((response: any) => response));
			})
		).subscribe(record => this.recordSubject$.next(record));
	}
	onEdit() {
		this.formMode = FormMode.Edit;
	}
	onCancel() {
		this.formMode = FormMode.View;
	}
	onSave(data: IRecordPageData) {
		const model = this.formState?.value;
		this._recordService.update(data.record.id, data.module.id, model).pipe(
			takeUntilDestroyed(this._destroyRef)
		).subscribe(record => {
			this.recordSubject$.next(record);
			this.formMode = FormMode.View;
		})
	}
	getKeyField(data: IRecordPageData)  {
		const keyField = data.module.keyField;
		const record = data.record;
		return record ? record[keyField] : '';
	}
	private _initPage() {
		this.page$ = this.module$.pipe(
			mergeMap(module => this._pageService.get().pipe(map(pages => pages.find(page => page.moduleId === module.id)), filter(isDefined)))
		)
	}
	private _initPageBuilder() {
		combineLatest([this.page$, this.record$]).pipe(
			takeUntilDestroyed(this._destroyRef)
		).subscribe(([page, record]) => {
			const mode = FormMode.View;
			this._store.dispatch(setFormMode({ mode }));
		})
	}
	private _initModule() {
		const moduleId = this._route.snapshot.paramMap.get('id');
		this.module$ = this._moduleService.get(moduleId);
	}
}